$(function () {
    $('a[href^="#"]').on('click', function() {
        let href = $.attr(this, 'href');

        $('html, body').animate({
            scrollTop: $(href).offset().top
        }, 500, function() {
            window.location.hash = href;
        });

        return false;
    });

    if ($.validator) {
        $.validator.addMethod(
            'regexp',
            function (value, element, regexp) {
                let re = new RegExp(regexp);
                return this.optional(element) || re.test(value);
            }
        );

        $("form.contact").validate({
            rules: {
                first_name: {
                    required: false,
                    minlength: 3,
                    maxlength: 64,
                },
                phone_number: {
                    required: true,
                    minlength: 6,
                    maxlength: 18,
                    regexp: "^[0-9\-\\s\)\(]+$",
                },
            },

            messages: {
                first_name: "",
                phone_number: ""
            },

            validClass: "is-valid",
            errorClass: "is-invalid",

            errorPlacement: function (error, element) {
                //just nothing, empty
            },

            submitHandler: function (form) {
                $.ajax({
                    url: form.action,
                    type: form.method,
                    data: $(form).serialize(),
                    success: function (json) {
                        let response = json;

                        if(response.success) {
                            $('#modal-success').modal('show');
                        }
                    },
                    error: function (json) {
                        let response = JSON.parse(json);

                        if(response.error) {
                            $('#modal-error').modal('show');
                        }
                    }
                });

                $(form).trigger("reset");
                $(form).find(".is-valid").removeClass("is-valid");
                $(form).find(".is-invalid").removeClass("is-invalid");
            }
        });

        $("form.main").validate({
            rules: {
                phone_number: {
                    required: true,
                    minlength: 6,
                    maxlength: 18,
                    regexp: "^[0-9\-\\s\)\(]+$",
                },
            },

            messages: {
                phone_number: "",
            },

            validClass: "is-valid",
            errorClass: "is-invalid",

            errorPlacement: function (error, element) {
                //just nothing, empty
            },

            submitHandler: function (form) {
                $.ajax({
                    url: form.action,
                    type: form.method,
                    data: $(form).serialize(),
                    success: function (json) {
                        let response = json;

                        if(response.success) {
                            $('#modal-success').modal('show');
                        }
                    },
                    error: function (json) {
                        let response = JSON.parse(json);

                        if(response.error) {
                            $('#modal-error').modal('show');
                        }
                    }
                });

                $(form).trigger("reset");
                $(form).find(".is-valid").removeClass("is-valid");
                $(form).find(".is-invalid").removeClass("is-invalid");
            }
        });
    }
});